import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { decrypt } from "../../utils/cryptoUtils";

const Gateway = () => {
  const [AccountName, setAccountName] = useState();

  const [CashFreeDeposit, setCashFreeDeposit] = useState(false);
  const [CashFreePayout, setCashFreePayout] = useState(false);

  const [ManualUpiId, setManualUpiId] = useState(false);
  const [ManualDeposit, setManualDeposit] = useState(false);
  const [ManualPayout, setManualPayout] = useState(false);
  const [ManualPayoutBank, setManualPayoutBank] = useState(false);

  const [WalletFlowPayOut, setWalletFlowPayOut] = useState(false);

  const [PhonepeDeposit, setPhonepeDeposit] = useState(false);
  const [UpigatwayDeposit, setUpigatwayDeposit] = useState(false);

  const [RazorDeposit, setRazorDeposit] = useState(false);
  const [RazorPayout, setRazorpayout] = useState(false);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);

  const [decentroPayout, setdecentropayout] = useState(false);
  const [decentroDeposit, setdecentroDeposit] = useState(false);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [QrCode, setQrCode] = useState("null");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  useEffect(() => {
    axios.get(baseUrl + "gatewaysettings/data", {})
      .then((res) => {
        if (res.data.status === 0) {
          console.log(res.data.msg);
        } else {
          
          const decryptedData = decrypt(res.data.data)
          
          setAccountName(decryptedData.AccountName);

          setCashFreeDeposit(decryptedData.CashFreeDeposit);
          setCashFreePayout(decryptedData.CashFreePayout);

          setQrCode(decryptedData.QrCode);
          setManualUpiId(decryptedData.ManualUpiId);
          setManualDeposit(decryptedData.ManualDeposit);
          setManualPayout(decryptedData.ManualPayout);
          setManualPayoutBank(decryptedData.ManualPayoutBank)

          setWalletFlowPayOut(decryptedData.WalletFlowPayOut);

          setPhonepeDeposit(decryptedData.PhonepeDeposit);
          setUpigatwayDeposit(decryptedData.UpigatwayDeposit);

          setSettingId(decryptedData._id);
          setRazorDeposit(decryptedData.RazorDeposit);
          setRazorpayout(decryptedData.RazorPayout);
          setRazorpayAuto(decryptedData.RazorpayAuto);

          setdecentroDeposit(decryptedData.decentroDeposit);
          setdecentropayout(decryptedData.decentroPayout);
          setdecentroAuto(decryptedData.decentroAuto);

        }
      });
      // eslint-disable-next-line
  }, []);



  useEffect(
    () => {
      const qrcode = document.getElementById('QrCode');
      qrcode.onchange = (e) => {
        const [file] = qrcode.files;
        setQrCode(file);
      };
    }, []
  )
  
  


  const handleSubmit1 = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("AccountName", AccountName);
    formData.append("CashFreeDeposit", CashFreeDeposit);
    formData.append("CashFreePayout", CashFreePayout);
    formData.append("QrCode", QrCode);
    formData.append("ManualUpiId", ManualUpiId);
    formData.append("ManualDeposit", ManualDeposit);
    formData.append("ManualPayout", ManualPayout);
    formData.append("ManualPayoutBank", ManualPayoutBank);

    formData.append("WalletFlowPayOut", WalletFlowPayOut);

    formData.append("PhonepeDeposit", PhonepeDeposit);
    formData.append("UpigatwayDeposit", UpigatwayDeposit);
    formData.append("RazorPayout", RazorPayout);
    formData.append("RazorDeposit", RazorDeposit);
    formData.append("RazorpayAuto", RazorpayAuto);
    formData.append("decentroPayout", decentroPayout);
    formData.append("decentroDeposit", decentroDeposit);
    formData.append("decentroAuto", decentroAuto);
    formData.append("settingId", settingId);

    const response = await axios.post(baseUrl + `gatewaysettings`, formData);
    
    if (response.data.status === 'success') {
      Swal.fire({
        title: 'data update successfully',
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Settings Not Submitted");
    }
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }





  return (
    <>
      <h4 className="text-uppercase text-warning font-weight-bold my-3">
        Payment Gateway Settings
      </h4>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="AccountName">Account Name</label>
          <input
            className="form-control"
            type="text"
            value={AccountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </div>
      </div>

      <h5 className="text-uppercase font-weight-bold my-3 text-warning">RazorPay</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        encType="multipart/form-date"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className="col-6 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazpay" className="col-6 my-1">
              Razorpay Payout
            </label>

            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="col-6 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase text-warning font-weight-bold my-3">Phonepe or Upigateway</h5>
            <label htmlFor="buttonrazdep" className="col-6 my-1">
              UPIgatway Deposite
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={UpigatwayDeposit}
              onChange={(e) => setUpigatwayDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>





            <label htmlFor="buttonphonepe" className="col-6 my-1">
              Phonepe Deposite
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={PhonepeDeposit}
              onChange={(e) => setPhonepeDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3 text-warning">Wallet Flow</h5>
            <label htmlFor="buttoncashpay" className="col-6 my-1">
              Wallet Flow PayOut
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={WalletFlowPayOut}
              onChange={(e) => setWalletFlowPayOut(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>            
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3 text-warning">CashFree</h5>
            <label htmlFor="buttoncashpay" className="col-6 my-1">
              CashFree Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={CashFreeDeposit}
              onChange={(e) => setCashFreeDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttoncashpayout" className="col-6 my-1">
              CashFree Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={CashFreePayout}
              onChange={(e) => setCashFreePayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>



            
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3 text-warning">Decentro</h5>
            <label htmlFor="buttondecpay" className="col-6 my-1"> Decentro payout </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-6 my-1">
              Decentro Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroDeposit}
              onChange={(e) => setdecentroDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-6 my-1">
              Decentro Auto
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroAuto}
              onChange={(e) => setdecentroAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3 text-warning">Manual Payment System</h5>
            {QrCode && <div>
              <div style={{ width: "100%" }}>
                <img src={baseUrl + QrCode} style={{ width: "100%" }} alt='scrshot' />
              </div>
            </div>
            }

            <div>
              {imagePreview && (
                <div>
                  <p>Image Preview:</p>
                  <img src={imagePreview} alt="Preview" style={{ width: '300px' }} />
                </div>
              )}
              <label htmlFor="buttonmpay" className="col-6 my-1">Payment Qr Code</label>
              <input type="file" id="QrCode" name="QrCode" onChange={handleImageChange} />
            </div>
            <div>
            <label htmlFor="buttonmpay" className="col-6 my-1">
              Enter UPI_id
            </label>
            <input
              className="form-control "
              name=""
              id=""
              value={ManualUpiId}
              onChange={(e) => setManualUpiId(e.target.value)}
            />
            </div>
            
            <label htmlFor="buttonmpay" className="col-6 my-1">
              Manual Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualDeposit}
              onChange={(e) => setManualDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonmpayout" className="col-6 my-1">
              Manual Payout by upi
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualPayout}
              onChange={(e) => setManualPayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
            <label htmlFor="buttonmpayout" className="col-6 my-1">
              Manual Payout by Bank
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={ManualPayoutBank}
              onChange={(e) => setManualPayoutBank(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-success">
              submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
